<template>
  <div>
    <div class="container-fluid pt-7 pb-5">
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Name"
                    :label="$t.translate('FRM_ASSESSMENT_SERVICE_NAME')"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <el-button
        v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT'])"
        type="primary"
        class="mb-3"
        @click="addService"
      >
        {{ $t.translate("BTN_ADD") }}
      </el-button>

      <div class="row">
        <div class="col">
          <div class="shadow bg-white p-around">
            <Table
              url="/assessment/list"
              :query="{ ...Filter }"
              ref="filttable"
              :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
              @sort-change="sortChange"
              :height="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) ? '60vh' : '64vh'"
            >
              <el-table-column
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
                prop="Name"
                :label="$t.translate('FRM_ASSESSMENT_SERVICE_NAME')"
                min-width="240"
              >
                <template #default="scope">
                  {{ $h.formatName(scope.row, "ServiceName") }}
                  <span v-if="$h.formatName(scope.row, 'ServiceNameExtra', true)">
                    - {{ $h.formatName(scope.row, "ServiceNameExtra") }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
                prop="Type"
                :label="$t.translate('FRM_TYPE_OF_DELIVERABLE')"
                width="230"
              >
                <template #header>
                  <TableFilter
                    :handleSelection="
                      (arr) => $refs.filttable.currentSelection(arr, 'Type')
                    "
                    :hasSelection="true"
                    :fieldName="$t.translate('FRM_TYPE_OF_DELIVERABLE')"
                    url="envconf/ServiceTypeOpts"
                    fieldKey="value"
                    fieldLabel="label"
                  />
                </template>
                <template #default="scope">
                  {{ $h.mapLabel(scope.row.TypeCode, ServiceTypeOpts) }}
                </template>
              </el-table-column>

              <el-table-column
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
                prop="TotalSubmission"
                :label="$t.translate('TH_CERT_STAT')"
                width="220"
              >
              </el-table-column>

              <el-table-column
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
                prop="LastCreated"
                :label="$t.translate('FRM_LAST_CREATED')"
                width="210"
              >
                <template #default="scope">
                  {{ $h.formatDateTime(scope.row.LastCreated) }}
                </template>
              </el-table-column>

              <el-table-column
                :fixed="$h.isSmall() ? false : 'right'"
                :label="$t.translate('FRM_STATUS')"
                width="100"
              >
                <template #header>
                  <TableFilter
                    :handleSelection="
                      (arr) => $refs.filttable.currentSelection(arr, 'StatusId')
                    "
                    :hasSelection="true"
                    :fieldName="$t.translate('FRM_STATUS')"
                    url="envconf/AssessmentStatusOpts"
                    fieldKey="value"
                    fieldLabel="label"
                  />
                </template>

                <template #default="scope">
                  <el-tag
                    :type="scope.row.IsActive ? 'success' : 'danger'"
                    effect="dark"
                    disable-transitions
                    >{{
                      scope.row.IsActive
                        ? $t.translate("LBL_ACTIVE")
                        : $t.translate("LBL_INACTIVE")
                    }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                fixed="right"
                :label="$t.translate('TH_ACTION')"
                width="70"
                class-name="pl-1 action-col"
              >
                <template #default="scope">
                  <el-dropdown
                    trigger="click"
                    :hide-on-click="true"
                    @command="(comd) => handleCommand(scope.row, comd)"
                  >
                    <el-button type="primary" size="mini">
                      <i class="el-icon-arrow-down"></i>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT'])"
                          command="edit"
                          >{{ $t.translate("BTN_EDIT") }}</el-dropdown-item
                        >
                        <el-dropdown-item v-else command="view">{{
                          $t.translate("BTN_VIEW")
                        }}</el-dropdown-item>

                        <el-dropdown-item
                          :disabled="
                            !$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) ||
                            scope.row.IsActive
                              ? true
                              : false
                          "
                          command="enable"
                          >{{ $t.translate("BTN_ACTIVATE") }}</el-dropdown-item
                        >
                        <el-dropdown-item
                          :disabled="
                            !$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) ||
                            !scope.row.IsActive
                              ? true
                              : false
                          "
                          command="disable"
                          >{{ $t.translate("BTN_DEACTIVATE") }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import { reactive, ref } from "vue";
import { router } from "@/utils/router";
import _ from "lodash";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { helpers } from "@/utils/helpers";

export default {
  components: { Table, TableFilter },
  setup() {
    let filttable = ref("filttable");
    let Filter = reactive({
      Name: "",

      SortBy: "Name",
      SortOrder: "ascending",
    });

    let { ServiceTypeOpts } = store.getters["config/getData"]?.envConf;

    const resetParams = () => {
      for (let key in Filter) {
        Filter[key] = "";
      }
      filterNow(1, Filter);
    };

    const filterNow = (page, filter = null) => {
      filttable.value.handleCurrentChange(page, filter);
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const addService = () => {
      router.changeRoute("/assessment_service/add");
    };

    const handleCommand = async (row, cmd) => {
      if (cmd == "edit") {
        return router.changeRoute("/assessment_service/edit/" + row.AssessmentServiceKey);
      }
      if (cmd == "view") {
        return router.changeRoute("/assessment_service/view/" + row.AssessmentServiceKey);
      }

      try {
        await ElMessageBox.confirm(
          translation
            .translate("MSG_DISABLE_SERVICE")
            .replace("{SERVICE}", helpers.formatName(row, "ServiceName")),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );

        let res = await post("/assessment/action", {
          AssessmentServiceKey: row.AssessmentServiceKey,
          IsActive: cmd == "enable" ? 1 : 0,
        });
        if (isValidResponse(res)) {
          filterNow();
        }
      } catch (err) {
        //proper handling
      }
    };

    return {
      filterAction,
      sortChange,
      resetParams,
      filterNow,
      filttable,
      handleCommand,
      Filter,
      ServiceTypeOpts,
      addService,
    };
  },
};
</script>
<style></style>
